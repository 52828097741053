    .logo{
      height: 70px;
      width: 70px;
      border-radius: 50%;
      background-color: #FF6200;
      position: relative;
      top: 4rem;
      /* left: 300px; */
      transition: height .25s ease, width .25s ease;
      /* transform: translate(-50%,-50%); */
      margin: 0 auto;
      z-index: 1;
    }
    
    
    .logo:before,
    .logo:after {
      content:'';
      display:block;
      position:absolute;
      top:0; right:0; bottom:0; left:0;
      border-radius:50%;
      border:2px solid #FF6200;
    }
    
    .logo:before {
      -webkit-animation: ripple 2s linear infinite;
      animation: ripple 2s linear infinite;
    }
    .logo:after {
      -webkit-animation: ripple 2s linear 1s infinite;
      animation: ripple 2s linear 1s infinite;
    }
    
    .logo:hover:before,
    .logo:hover:after {
      -webkit-animation: none;
      animation: none;
    }
    
    @-webkit-keyframes ripple{
      0% {-webkit-transform:scale(1); }
      75% {-webkit-transform:scale(1.75); opacity:1;}
      100% {-webkit-transform:scale(2); opacity:0;}
    }
    
    @keyframes ripple {
      0% {
        box-shadow: 0 0 0 .7rem rgba(255, 98, 0, 0.808);
      }
      50% {
        box-shadow: 0 0 0 3.5rem rgb(255, 165, 0, 0.5);
      }
      100% {
        box-shadow: 0 0 0 5rem rgb(255, 165, 0, 0);
      }
    }

    @media (max-width: 600px){
      .logo{
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background-color: #FF6200;
        position: relative;
        top: 2.5rem;
        transition: height .25s ease, width .25s ease;
        margin: 0 auto;
        z-index: 1;
        margin-top: 2rem;
      }
      @keyframes ripple {
        0% {
          box-shadow: 0 0 0 .7rem rgba(255, 98, 0, 0.808);
        }
        50% {
          box-shadow: 0 0 0 1.5rem rgb(255, 165, 0, 0.5);
        }
        100% {
          box-shadow: 0 0 0 2.5rem rgb(255, 165, 0, 0);
        }
      }
    }
    .underlineLink {
      position: relative;
      text-decoration: none;
      transition: font-size 0.3s ease-in-out;
      
    }
    .underlineLink.underlineLinkSelected {
      font-size: 16px;
      font-family: Galano Grotesque Regular,Arial;
    }
    
    .underlineLink::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 3px; /* Line height and color */
      background-color: #FF8200;
      transition: width 0.3s ease-in-out; /* Animation duration and easing function */
    }
    .underlineLink.underlineLinkSelected::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 3px; /* Line height and color */
      background-color: #FF8200;
      transition: width 0.3s ease-in-out; /* Animation duration and easing function */
    }
    .underlineLink:hover {
      font-size: 20px; /* Zoom effect on hover */
    }
    
    .underlineLink:hover::after {
      width: 100%; /* Full underline on hover */
    }
    
    /* Animation keyframes to progressively underline from left to right */
    @keyframes underline-left-to-right {
      0% {
        width: 0;
        left: 0;
      }
      50% {
        width: 100%;
        left: 0;
      }
      100% {
        width: 100%;
        left: 100%;
      }
    }